<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Hisob faktura malumotlari</h3>
              </div>
            </div>

            <div class="card-body" v-if="getDetails !== undefined">
              <div class="input__wrapper">
                <div class="input">
                  <label>Kontragent kontrakt raqami</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.contragent_contract_number"
                  />
                </div>

                <div class="input">
                  <label>Kontragent nomi</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.contragent_name"
                  />
                </div>
                <div class="input">
                  <label>Sana</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.oper_date"
                  />
                </div>
                <div class="input">
                  <label>Reg raqam</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.reg_number"
                  />
                </div>

                <div class="input">
                  <label>Sklad</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.placement_warehouse_name"
                  />
                </div>

                <div class="input">
                  <label>Jami</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.get_doc_products_total.total"
                  />
                </div>

                <div class="input">
                  <label>Summa (NDS)</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.get_doc_products_total.nds_summa"
                  />
                </div>
                <div class="input">
                  <label>Jami summa (NDS blan)</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="
                      getDetails.get_doc_products_total.total_withnds_summa
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id
    }
  },
  computed: {
    getDetails() {
      const data = this.$store.getters.countragentKontractFacturas

      return data.find((obj) => obj.id == this.$route.params.id)
    }
  }
}
</script>

<style scoped>
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.input label {
  display: block;
  font-size: 13px;
}
.input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.input:last-child {
  margin-right: 0;
}
.input-text {
  background-color: rgb(142 190 253 / 14%);
  padding: 1rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  font-size: 16px;
  border-radius: 3px;
}
</style>
